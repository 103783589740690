import range from "lodash.range";
import * as d3 from "d3";
import * as cmk_figures from "cmk_figures";
import * as crossfilter from "crossfilter2";
import {partitionableDomain, domainIntervals} from "number_format";
import {PlotDefinition} from "ntop_top_talkers";
import {ElementSize} from "cmk_figures";

interface Domain {
    x: [number, number];
    y: [number, number];
}

// Used for rapid protoyping, bypassing webpack
//var cmk_figures = cmk.figures; /*eslint-disable-line no-undef*/
//var dc = dc; /*eslint-disable-line no-undef*/
//var d3 = d3; /*eslint-disable-line no-undef*/
//var crossfilter = crossfilter; /*eslint-disable-line no-undef*/

// The TimeseriesFigure provides a renderer mechanic. It does not actually render the bars/dot/lines/areas.
// Instead, it manages a list of subplots. Each subplot receives a drawing area and render its data when when
// being told by the TimeseriesFigure

interface TimeseriesFigureData extends cmk_figures.FigureData {
    zoom_settings?;
}

class TimeseriesFigure extends cmk_figures.FigureBase<TimeseriesFigureData> {
    _subplots;
    _subplots_by_id;
    g;
    _tooltip;
    _legend_dimension;
    tooltip_generator?: cmk_figures.FigureTooltip;
    scale_x;
    orig_scale_x;
    scale_y;
    orig_scale_y;
    lock_zoom_x?: boolean;
    lock_zoom_y?: boolean;
    lock_zoom_x_scale?: boolean;
    _legend;
    _title;
    _zoom_active?: boolean;
    _zoom;
    _current_zoom: any;
    _title_url?: string;
    _x_domain;
    _y_domain;
    _y_domain_step;
    ident() {
        return "timeseries";
    }

    getEmptyData() {
        return cmk_figures.getEmptyBasicFigureData();
    }

    constructor(div_selector, fixed_size = null) {
        super(div_selector, fixed_size);
        this._subplots = [];
        this._subplots_by_id = {};
        this.margin = {top: 20, right: 10, bottom: 30, left: 65};
        this._legend_dimension = this._crossfilter.dimension(d => d.tag);
    }

    get_id() {
        return this._div_selection.attr("id");
    }

    initialize() {
        // TODO: check double diff, currently used for absolute/auto styling
        this._div_selection
            .classed("timeseries", true)
            .style("overflow", "visible");
        const main_div = this._div_selection
            .append("div")
            .classed("figure_content", true)
            .style("position", "absolute")
            .style("display", "inline-block")
            .style("overflow", "visible")
            .on("click", event => this._mouse_click(event))
            .on("mousedown", event => this._mouse_down(event))
            .on("mousemove", event => this._mouse_move(event))
            .on("mouseleave", event => this._mouse_out(event));

        // The main svg, covers the whole figure
        this.svg = main_div
            .append("svg")
            .datum(this)
            .classed("renderer", true)
            .style("overflow", "visible");

        // The g for the subplots, checks margins
        this.g = this.svg.append("g");

        this._tooltip = main_div.append("div");
        this.tooltip_generator = new cmk_figures.FigureTooltip(this._tooltip);
        // TODO: uncomment to utilize the tooltip collapser
        //let collapser = this._tooltip.append("div").classed("collapser", true);
        //collapser.append("img").attr("src", "themes/facelift/images/tree_closed.svg")
        //    .on("click", ()=>{
        //        collapser.classed("active", !collapser.classed("active"));
        //    });

        // All subplots share the same scale
        this.scale_x = d3.scaleTime();
        this.orig_scale_x = d3.scaleTime();
        this.scale_y = d3.scaleLinear();
        this.orig_scale_y = d3.scaleLinear();
        this._setup_legend();
        this.resize();
        this._setup_zoom();

        this.lock_zoom_x = false;
        this.lock_zoom_y = false;
        this.lock_zoom_x_scale = false;
    }

    _setup_legend() {
        this._legend = this._div_selection
            .select("div.figure_content")
            .append("div")
            .classed("legend", true)
            .style("display", "none")
            .style("top", this.margin.top + "px");
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    _mouse_down(event) {}

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    _mouse_click(event) {}

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    _mouse_out(event) {}

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    _mouse_move(event) {}

    crossfilter() {
        if (!arguments.length) {
            return this._crossfilter;
        }
        this._crossfilter = crossfilter;
        return this;
    }

    get_plot_id(plot_id) {
        return this._subplots_by_id[plot_id];
    }

    resize() {
        let new_size = this._fixed_size as ElementSize | null;
        if (new_size === null)
            new_size = {
                // @ts-ignore
                width: this._div_selection.node().parentNode.offsetWidth,
                // @ts-ignore
                height: this._div_selection.node().parentNode.offsetHeight,
            };
        this.figure_size = new_size;
        if (this._title) {
            this.margin.top = 8 + 24; // 8 timeseries y-label margin, 24 from UX project title
            this._adjust_margin();
        }
        this.plot_size = {
            width: new_size.width! - this.margin.left - this.margin.right,
            height:
                new_size.height! -
                this.margin.top -
                this.margin.bottom -
                this._get_legend_height(),
        };
        this.tooltip_generator?.update_sizes(this.figure_size, this.plot_size);
        this._div_selection.style("height", this.figure_size.height + "px");
        this.svg.attr("width", this.figure_size.width);
        this.svg.attr("height", this.figure_size.height);
        this.g.attr(
            "transform",
            "translate(" + this.margin.left + "," + this.margin.top + ")"
        );

        this.orig_scale_x.range([0, this.plot_size.width]);
        this.orig_scale_y.range([this.plot_size.height, 0]);
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    _adjust_margin() {}
    _get_legend_height() {
        return 0;
    }
    _setup_zoom() {
        this._current_zoom = d3.zoomIdentity;
        this._zoom_active = false;
        this._zoom = d3
            .zoom()
            .scaleExtent([0.01, 100])
            .on("zoom", event => {
                const last_y = this._current_zoom.y;
                if (this.lock_zoom_x) {
                    event.transform.x = 0;
                    event.transform.k = 1;
                }
                if (this.lock_zoom_x_scale) event.transform.k = 1;

                this._current_zoom = event.transform;
                if (event.sourceEvent.type === "wheel")
                    this._current_zoom.y = last_y;
                this._zoomed();
            });
        this.svg.call(this._zoom);
    }

    _zoomed() {
        this._zoom_active = true;
        this.render();
        this._zoom_active = false;
    }

    add_plot(plot) {
        plot.renderer(this);
        this._subplots.push(plot);
        this._subplots_by_id[plot.definition.id] = plot;

        if (plot.main_g) {
            const removed = plot.main_g.remove();
            // @ts-ignore
            this._div_selection.select("g").select(function () {
                // @ts-ignore
                this.appendChild(removed.node());
            });
        }
    }

    remove_plot(plot) {
        const idx = this._subplots.indexOf(plot);
        if (idx > -1) {
            this._subplots.splice(idx, 1);
            delete this._subplots_by_id[plot.definition.id];
        }
        plot.remove();
    }

    update_data(data) {
        data.data.forEach(d => {
            d.date = new Date(d.timestamp * 1000);
        });
        cmk_figures.FigureBase.prototype.update_data.call(this, data);
        this._title = data.title;
        this._title_url = data.title_url;
        this._update_zoom_settings();
        this._update_crossfilter(data.data);
        this._update_subplots(data.plot_definitions);
        this._compute_stack_values();
    }

    _update_zoom_settings() {
        const settings = this._data.zoom_settings;
        if (settings === undefined) return;

        ["lock_zoom_x", "lock_zoom_y", "lock_zoom_x_scale"].forEach(option => {
            if (settings[option] == undefined) return;
            this[option] = settings[option];
        });
    }

    update_gui() {
        this.update_domains();
        this.resize();
        this.render();
    }

    update_domains() {
        const all_domains: Domain[] = [];
        this._subplots.forEach(subplot => {
            const domains = subplot.get_domains();
            if (domains) all_domains.push(domains);
        });
        const now = new Date();
        let time_range = cmk_figures.getIn(this, "_data", "time_range");
        if (time_range) {
            time_range = time_range.map(d => new Date(d * 1000));
        } else {
            time_range = [now, now];
        }

        const domain_min = d3.min(all_domains, d => d.x[0]);
        const domain_max = d3.max(all_domains, d => d.x[1]);
        this._x_domain = [
            // @ts-ignore
            domain_min < time_range[0] ? domain_min : time_range[0],
            // @ts-ignore
            domain_max > time_range[1] ? domain_max : time_range[1],
        ];

        const y_tick_count = Math.max(2, Math.ceil(this.plot_size.height / 50));
        const [min_val, max_val, step] = partitionableDomain(
            [
                d3.min(all_domains, d => d.y[0]),
                d3.max(all_domains, d => d.y[1]),
            ],
            y_tick_count,
            domainIntervals(
                cmk_figures.getIn(
                    this,
                    "_data",
                    "plot_definitions",
                    0,
                    "metric",
                    "unit",
                    "stepping"
                )
            )
        );
        this._y_domain = [min_val, max_val];
        this._y_domain_step = step;
        this.orig_scale_x.domain(this._x_domain);
        this.orig_scale_y.domain(this._y_domain);
    }

    _update_crossfilter(data) {
        this._crossfilter.remove(() => true);
        this._crossfilter.add(data);
    }

    _update_subplots(plot_definitions) {
        // Mark all existing plots for removal
        this._subplots.forEach(subplot => {
            subplot.marked_for_removal = true;
        });

        plot_definitions.forEach(definition => {
            if (this._plot_exists(definition.id)) {
                delete this._subplots_by_id[definition.id][
                    "marked_for_removal"
                ];
                // Update definition of existing plot
                this._subplots_by_id[definition.id].definition = definition;
                return;
            }
            // Add new plot
            this.add_plot(this.create_plot_from_definition(definition));
        });

        // Remove vanished plots
        this._subplots.forEach(subplot => {
            if (subplot.marked_for_removal) this.remove_plot(subplot);
        });

        this._subplots.forEach(subplot => subplot.update_transformed_data());
    }

    create_plot_from_definition(definition) {
        const new_plot = new (subplot_factory.get_plot(definition.plot_type))(
            definition
        );
        const dimension = this._crossfilter.dimension(d => d.date);
        new_plot.renderer(this);
        new_plot.dimension(dimension);
        return new_plot;
    }

    _plot_exists(plot_id) {
        for (const idx in this._subplots) {
            if (this._subplots[idx].definition.id == plot_id) return true;
        }
        return false;
    }

    render() {
        this.render_title(this._title, this._title_url);

        // Prepare scales, the subplots need them to render the data
        this._prepare_scales();

        // Prepare render area for subplots
        // TODO: move to plot creation
        this._subplots.forEach(subplot => {
            subplot.prepare_render();
        });

        // Render subplots
        this._subplots.forEach(subplot => {
            subplot.render();
        });

        this.render_axis();
        this.render_grid();
        this.render_legend();
    }

    render_legend() {
        this._legend.style(
            "display",
            this._subplots.length > 1 ? null : "none"
        );

        if (this._subplots.length <= 1) return;

        const items = this._legend
            .selectAll(".legend_item")
            .data(this._subplots, d => d.definition.id);
        items.exit().remove();
        const new_items = items
            .enter()
            .append("div")
            .classed("legend_item", true)
            .classed("noselect", true);

        new_items.append("div").classed("color_code", true);
        new_items.style("pointer-events", "all");
        new_items.append("label").text(d => d.definition.label);
        new_items.on("click", event => {
            const item = d3.select(event.currentTarget);
            item.classed("disabled", !item.classed("disabled"));
            // @ts-ignore
            item.style(
                "background",
                // @ts-ignore
                (item.classed("disabled") && "grey") || null
            );
            const all_disabled: string[] = [];
            this._div_selection.selectAll(".legend_item.disabled").each(d => {
                // @ts-ignore
                all_disabled.push(d.definition.use_tags[0]);
            });
            this._legend_dimension.filter(d => {
                return all_disabled.indexOf(d) == -1;
            });
            this._compute_stack_values();
            this._subplots.forEach(subplot =>
                subplot.update_transformed_data()
            );
            this.update_gui();
        });

        new_items
            .merge(items)
            .selectAll("div")
            .style("background", d => d.get_color());
    }

    _prepare_scales() {
        this.scale_x = this._current_zoom.rescaleX(this.orig_scale_x);
        this.scale_y.range(this.orig_scale_y.range());
        this.scale_y.domain(this.orig_scale_y.domain());

        if (this.lock_zoom_y) this.scale_y.domain(this.orig_scale_y.domain());
        else {
            const y_max = this.orig_scale_y.domain()[1];
            const y_stretch = Math.max(
                0.05 * y_max,
                y_max + (this._current_zoom.y / 100) * y_max
            );
            this.scale_y.domain([0, y_stretch]);
        }
    }

    _find_metric_to_stack(definition, all_disabled) {
        if (!this._subplots_by_id[definition.stack_on]) return null;
        if (all_disabled.indexOf(definition.stack_on) == -1)
            return definition.stack_on;
        if (this._subplots_by_id[definition.stack_on].definition.stack_on)
            return this._find_metric_to_stack(
                this._subplots_by_id[definition.stack_on].definition,
                all_disabled
            );
        return null;
    }

    _compute_stack_values() {
        // Disabled metrics
        const all_disabled: string[] = [];
        this._div_selection.selectAll(".legend_item.disabled").each(d => {
            // @ts-ignore
            all_disabled.push(d.definition.id);
        });

        // Identify stacks
        const required_stacks = {};
        this._subplots.forEach(subplot => {
            subplot.stack_values = null;
            if (subplot.definition.stack_on) {
                const stack_on = this._find_metric_to_stack(
                    subplot.definition,
                    all_disabled
                );
                if (stack_on != null)
                    required_stacks[subplot.definition.id] = stack_on;
            }
        });

        // Order stacks
        // TBD:

        // Update stacks
        const base_values = {};
        for (const target in required_stacks) {
            const source = this._subplots_by_id[required_stacks[target]];
            source.update_transformed_data();
            const references = {};
            source.transformed_data.forEach(point => {
                references[point.timestamp] = point.value;
            });
            base_values[source.definition.id] = references;
            this._subplots_by_id[target].stack_values = references;
            this._subplots_by_id[target].update_transformed_data();
        }
    }

    render_axis() {
        const x = this.g
            .selectAll("g.x_axis")
            .data([null])
            .join("g")
            .classed("x_axis", true)
            .classed("axis", true);

        const x_tick_count = Math.min(Math.ceil(this.plot_size.width / 65), 6);
        this.transition(x).call(
            d3
                .axisBottom(this.scale_x)
                .tickFormat(d => {
                    // @ts-ignore
                    if (d.getMonth() === 0 && d.getDate() === 1)
                        // @ts-ignore
                        return d3.timeFormat("%Y")(d);
                    // @ts-ignore
                    else if (d.getHours() === 0 && d.getMinutes() === 0)
                        // @ts-ignore
                        return d3.timeFormat("%m-%d")(d);
                    // @ts-ignore
                    return d3.timeFormat("%H:%M")(d);
                })
                .ticks(x_tick_count)
        );
        x.attr("transform", "translate(0," + this.plot_size.height + ")");

        const y = this.g
            .selectAll("g.y_axis")
            .data([null])
            .join("g")
            .classed("y_axis", true)
            .classed("axis", true);

        const render_function = this.get_scale_render_function();
        this.transition(y).call(
            d3
                .axisLeft(this.scale_y)
                .tickFormat(d => render_function(d).replace(/\.0+\b/, ""))
                .ticks(this._y_ticks())
        );
    }

    _y_ticks(): number {
        const max = range(
            this._y_domain[0],
            this._y_domain[1] + 1,
            this._y_domain_step
        ).length;
        const min = Math.ceil(this.plot_size.height / 65);
        return Math.min(min, max);
    }

    render_grid() {
        // Grid
        const height = this.plot_size.height;
        this.g
            .selectAll("g.grid.vertical")
            .data([null])
            .join("g")
            .classed("grid vertical", true)
            .attr("transform", "translate(0," + height + ")")
            .call(
                d3
                    .axisBottom(this.scale_x)
                    .ticks(5)
                    .tickSize(-height)
                    // @ts-ignore
                    .tickFormat("")
            );

        const width = this.plot_size.width;
        this.g
            .selectAll("g.grid.horizontal")
            .data([null])
            .join("g")
            .classed("grid horizontal", true)
            .call(
                d3
                    .axisLeft(this.scale_y)
                    .tickSize(-width)
                    .ticks(this._y_ticks() * 2)
                    // @ts-ignore
                    .tickFormat("")
            );
    }

    transition(selection) {
        if (this._zoom_active) {
            selection.interrupt();
            return selection;
        } else return selection.transition().duration(500);
    }
}

cmk_figures.figure_registry.register(TimeseriesFigure);

// A generic average scatterplot chart with median/mean lines and scatterpoints for each instance
// Requirements:
//     Subplots with id
//       - id_scatter
//       - id_mean
//       - id_median
//     Data tagged with
//       - line_mean
//       - line_median
//       - scatter
class AverageScatterplotFigure extends TimeseriesFigure {
    _selected_scatterpoint;
    _selected_meanpoint;
    ident() {
        return "average_scatterplot";
    }

    _mouse_down(event) {
        // event.button == 1 equals a pressed mouse wheel
        if (event.button == 1 && this._selected_scatterpoint) {
            window.open(this._selected_scatterpoint.url);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _mouse_click(event) {
        if (this._selected_scatterpoint)
            window.location = this._selected_scatterpoint.url;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _mouse_out(event) {
        this.g.select("path.pin").remove();
        this._tooltip.selectAll("table").remove();
        this._tooltip.style("opacity", 0);
        this.tooltip_generator?.deactivate();
    }

    _mouse_move(event) {
        const ev = event;
        // TODO KO: clean up these mouse events for better performance
        if (
            !["svg", "path"].includes(ev.target.tagName) ||
            ev.layerX < this.margin.left ||
            ev.layerY < this.margin.top ||
            ev.layerX > this.margin.left + this.plot_size.width ||
            ev.layerY > this.margin.top + this.plot_size.height
        ) {
            this._mouse_out(event);
            return;
        }
        if (!this._crossfilter || !this._subplots_by_id["id_scatter"]) return;

        // TODO AB: change this dimensions to members
        //          filter_dimension -> tag_dimension
        //          result_dimension -> date_dimension
        const filter_dimension = this._crossfilter.dimension(d => d);
        const timestamp_dimension = this._crossfilter.dimension(
            d => d.timestamp
        );

        // Find focused scatter point and highlight it
        const scatter_plot = this._subplots_by_id["id_scatter"];
        const scatterpoint = scatter_plot.quadtree.find(
            ev.layerX - this.margin.left,
            ev.layerY - this.margin.top,
            10
        );
        this._selected_scatterpoint = scatterpoint;

        let use_date: Date;
        scatter_plot.redraw_canvas();
        if (scatterpoint !== undefined) {
            use_date = scatterpoint.date;
            // Highlight all incidents, based on this scatterpoint's label
            const ctx = scatter_plot.canvas.node().getContext("2d");
            const points = scatter_plot.transformed_data.filter(
                d => d.label == scatterpoint.label
            );
            const line = d3
                .line()
                // @ts-ignore
                .x(d => d.scaled_x)
                // @ts-ignore
                .y(d => d.scaled_y)
                .context(ctx);
            ctx.beginPath();
            line(points);
            const hilited_host_color = this._get_css("stroke", "path", [
                "host",
                "hilite",
            ]);
            ctx.strokeStyle = hilited_host_color;
            ctx.stroke();

            // Highlight selected point
            ctx.beginPath();
            ctx.arc(
                scatterpoint.scaled_x,
                scatterpoint.scaled_y,
                3,
                0,
                Math.PI * 2,
                false
            );
            const hilited_node_color = this._get_css("fill", "circle", [
                "scatterdot",
                "hilite",
            ]);
            ctx.fillStyle = hilited_node_color;
            ctx.fill();
            ctx.stroke();
        } else {
            use_date = this.scale_x.invert(ev.layerX - this.margin.left);
        }

        // @ts-ignore
        const nearest_bisect = d3.bisector(d => d.timestamp).left;

        // Find nearest mean point
        filter_dimension.filter(d => d.tag == "line_mean");
        const results = timestamp_dimension.bottom(Infinity);
        const idx = nearest_bisect(results, use_date.getTime() / 1000);
        const mean_point = results[idx];

        // Get corresponding median point
        filter_dimension.filter(d => d.tag == "line_median");
        const median_point = timestamp_dimension.bottom(Infinity)[idx];

        if (mean_point == undefined || median_point == undefined) {
            filter_dimension.dispose();
            timestamp_dimension.dispose();
            return;
        }

        // Get scatter points for this date
        filter_dimension.filter(
            d => d.timestamp == mean_point.timestamp && d.tag == "scatter"
        );
        const scatter_matches = timestamp_dimension.top(Infinity);
        scatter_matches.sort((first, second) => first.value > second.value);
        const top_matches = scatter_matches.slice(-5, -1).reverse();
        const bottom_matches = scatter_matches.slice(0, 4).reverse();

        this._selected_meanpoint = mean_point;
        this._update_pin();

        this._render_tooltip(
            event,
            top_matches,
            bottom_matches,
            mean_point,
            median_point,
            scatterpoint
        );

        filter_dimension.dispose();
        timestamp_dimension.dispose();
    }

    _zoomed() {
        super._zoomed();
        this._update_pin();
    }

    _update_pin() {
        if (this._selected_meanpoint) {
            this.g.select("path.pin").remove();
            const x = this.scale_x(this._selected_meanpoint.date);
            this.g
                .append("path")
                .classed("pin", true)
                .attr(
                    "d",
                    d3.line()([
                        [x, 0],
                        [x, this.plot_size.height],
                    ])
                )
                .attr("pointer-events", "none");
        }
    }

    _render_tooltip(
        event,
        top_matches,
        bottom_matches,
        mean_point,
        median_point,
        scatterpoint
    ) {
        this._tooltip.selectAll("table").remove();

        const table = this._tooltip.append("table");

        const date_row = table.append("tr").classed("date", true);
        date_row.append("td").text(mean_point.date).attr("colspan", 2);
        const formatter = cmk_figures.plot_render_function(
            cmk_figures.getIn(this, "_subplots", 0, "definition")
        );

        const mean_row = table.append("tr").classed("mean", true);
        mean_row.append("td").text(mean_point.label);
        mean_row.append("td").text(formatter(mean_point.value));
        const median_row = table.append("tr").classed("median", true);
        median_row.append("td").text(median_point.label);
        median_row.append("td").text(formatter(median_point.value));

        if (scatterpoint) {
            const scatter_row = table
                .append("tr")
                .classed("scatterpoint", true);
            const hilited_host_color = this._get_css("stroke", "path", [
                "host",
                "hilite",
            ]);
            scatter_row
                .append("td")
                .text(scatterpoint.tooltip + " (selected)")
                .style("color", hilited_host_color);
            scatter_row.append("td").text(formatter(scatterpoint.value));
        }

        const top_rows = table
            .selectAll("tr.top_matches")
            .data(top_matches)
            .enter()
            .append("tr")
            .classed("top_matches", true);
        top_rows.append("td").text(d => d.tooltip);
        top_rows.append("td").text(d => formatter(d.value));

        const bottom_rows = table
            .selectAll("tr.bottom_matches")
            .data(bottom_matches)
            .enter()
            .append("tr")
            .classed("bottom_matches", true);
        bottom_rows.append("td").text(d => d.tooltip);
        bottom_rows.append("td").text(d => formatter(d.value));

        this.tooltip_generator?.activate();
        this.tooltip_generator?.update_position(event);
    }

    _get_css(prop, tag, classes) {
        const obj = this.svg.append(tag);
        classes.forEach(cls => obj.classed(cls, true));
        const css = obj.style(prop);
        obj.remove();
        return css;
    }
}

cmk_figures.figure_registry.register(AverageScatterplotFigure);

// A single metric figure with optional graph rendering in the background
class SingleMetricFigure extends TimeseriesFigure {
    ident() {
        return "single_metric";
    }

    constructor(div_selector, fixed_size = null) {
        super(div_selector, fixed_size);
        this.margin = {top: 0, right: 0, bottom: 0, left: 0};
    }

    initialize() {
        super.initialize();
        this.lock_zoom_x = true;
        this.lock_zoom_y = true;
        this.lock_zoom_x_scale = true;
    }

    _adjust_margin() {
        this.margin.top -= 8; // it has no timeseries y-labels scale
    }

    _setup_zoom() {
        this._current_zoom = d3.zoomIdentity;
    }

    update_domains() {
        TimeseriesFigure.prototype.update_domains.call(this);
        const display_range = this._dashlet_spec.display_range;
        // display_range could be null, or [str, [int, int]]
        if (Array.isArray(display_range) && display_range[0] === "fixed") {
            this._y_domain = display_range[1][1];
            this.orig_scale_y.domain(this._y_domain);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    render_legend() {}
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    render_grid() {}
    render_axis() {
        const toggle_range_display = this._dashlet_spec.toggle_range_display;
        if (
            this._subplots.filter(d => d.definition.plot_type == "area")
                .length == 0 ||
            !toggle_range_display
        ) {
            this.g.selectAll("text.range").remove();
            return;
        }

        const render_function = this.get_scale_render_function();
        const domain = this._y_domain;

        const domain_labels = [
            {
                value: render_function(domain[0]),
                y: this.plot_size.height - 7,
                x: 5,
            },
            {
                value: render_function(domain[1]),
                y: 15,
                x: 5,
            },
        ];

        this.g
            .selectAll("text.range")
            .data(domain_labels)
            .join("text")
            .classed("range", true)
            .text(d => d.value)
            .style("font-size", "10pt")
            .attr("x", d => d.x)
            .attr("y", d => d.y);
    }
}

cmk_figures.figure_registry.register(SingleMetricFigure);

// Base class for all SubPlots
// It renders its data into a <g> provided by the renderer instance
class SubPlot {
    definition;
    _renderer;
    _dimension;
    transformed_data;
    stack_values;
    main_g;
    svg;
    marked_for_removal: boolean | undefined;
    constructor(definition) {
        this.definition = definition;

        this._renderer = null; // Graph which renders this plot
        this._dimension = null; // The crossfilter dimension (x_axis)
        this.transformed_data = []; // data shifted/scaled by subplot definition

        this.stack_values = null; // timestamp/value pairs provided by the target plot

        this.main_g = null; // toplevel g, contains svg/canvas elements
        this.svg = null; // svg content
        return this;
    }

    _get_css(prop, tag, classes) {
        const obj = this.svg.append(tag);
        classes.forEach(cls => obj.classed(cls, true));
        const css = obj.style(prop);
        obj.remove();
        return css;
    }

    renderer(renderer) {
        if (!arguments.length) {
            return this._renderer;
        }
        this._renderer = renderer;
        this.prepare_render();
        return this;
    }

    remove() {
        this.main_g.transition().duration(1000).style("opacity", 0).remove();
    }

    get_color() {
        if (this.definition.color) return d3.color(this.definition.color);
        return;
    }

    get_opacity() {
        if (this.definition.opacity) return this.definition.opacity;
        return 1;
    }

    dimension(dimension) {
        if (!arguments.length) {
            return this._dimension;
        }
        this._dimension = dimension;
        return this;
    }

    get_domains(): Domain | undefined {
        // Return the x/y domain boundaries
        if (this.definition.is_scalar) return;

        return {
            x: [
                // @ts-ignore
                d3.min(this.transformed_data, d => d.date),
                // @ts-ignore
                d3.max(this.transformed_data, d => d.date),
            ],
            // @ts-ignore
            y: [0, d3.max(this.transformed_data, d => d.value)],
        };
    }

    get_legend_data(start, end) {
        // Returns the currently shown x/y domain boundaries
        if (this.definition.is_scalar) return {data: this.transformed_data};

        const data = this.transformed_data.filter(d => {
            return d.timestamp >= start && d.timestamp <= end;
        });

        const value_accessor =
            this.definition.stack_on && this.definition.stack_values
                ? "unstacked_value"
                : "value";
        return {
            // @ts-ignore
            x: [d3.min(data, d => d.date), d3.max(data, d => d.date)],
            // @ts-ignore
            y: [0, d3.max(data, d => d[value_accessor])],
            data: data,
        };
    }

    prepare_render() {
        const plot_size = this._renderer.plot_size;

        // The subplot main_g contains all graphical components for this subplot
        this.main_g = this._renderer.g
            .selectAll("g.subplot_main_g." + this.definition.id)
            .data([null])
            .join("g")
            .classed("subplot_main_g", true)
            .classed(this.definition.id, true);

        if (this.definition.css_classes)
            this.main_g.classed(this.definition.css_classes.join(" "), true);

        // Default drawing area
        this.svg = this.main_g
            .selectAll("svg.subplot")
            .data([null])
            .join("svg")
            .attr("width", plot_size.width)
            .attr("height", plot_size.height)
            .classed("subplot", true);
    }

    // Currently unused. Handles the main_g of SubPlots between different TimeseriesFigure instances
    migrate_to(other_renderer) {
        let delta: null | {x: number; y: number} = null;
        if (this._renderer) {
            this._renderer.remove_plot(this);
            const old_box = this._renderer._div_selection
                .node()
                .getBoundingClientRect();
            const new_box = other_renderer._div_selection
                .node()
                .getBoundingClientRect();
            delta = {x: old_box.x - new_box.x, y: old_box.top - new_box.top};
        }
        other_renderer.add_plot(this);
        if (delta) {
            other_renderer.g
                .select(".subplot_main_g." + this.definition.id)
                .attr("transform", "translate(" + delta.x + "," + delta.y + ")")
                .transition()
                .duration(2500)
                .attr("transform", "translate(0,0) scale(1)");
        }

        // TODO: Refactor, introduces dashlet dependency
        const dashlet = d3.select(
            other_renderer._div_selection.node().closest(".dashlet")
        );
        if (!dashlet.empty())
            dashlet
                .style("z-index", 1000)
                .transition()
                .duration(2000)
                .style("z-index", 0);

        other_renderer.remove_loading_image();
        other_renderer.update_gui();
    }

    get_coord_shifts() {
        const shift_seconds = this.definition.shift_seconds || 0;
        const shift_y = this.definition.shift_y || 0;
        const scale_y = this.definition.scale_y || 1;
        return [shift_seconds, shift_y, scale_y];
    }

    update_transformed_data() {
        const shifts = this.get_coord_shifts();
        const shift_second = shifts[0];
        const shift_y = shifts[1];
        const scale_y = shifts[2];

        let data = this._dimension.top(Infinity);
        data = data.filter(d => d.tag == this.definition.use_tags[0]);
        //let data = this._dimension.filter(d=>d.tag == this.definition.use_tags[0]).top(Infinity);
        //this._dimension.filterAll();

        // Create a deepcopy
        this.transformed_data = JSON.parse(JSON.stringify(data));
        this.transformed_data.forEach(point => {
            point.timestamp += shift_second;
            point.date = new Date(point.timestamp * 1000);
        });

        if (shift_y != 0)
            this.transformed_data.forEach(point => {
                point.value += shift_y;
            });

        if (scale_y != 1)
            this.transformed_data.forEach(point => {
                point.value *= scale_y;
            });

        if (this.stack_values != null)
            this.transformed_data.forEach(point => {
                point.unstacked_value = point.value;
                point.value += this.stack_values[point.timestamp] || 0;
            });
    }

    ident(): string {
        throw Error("Method not implemented");
    }
}

function line_draw_fn(subplot) {
    return (
        d3
            .line()
            .curve(d3.curveLinear)
            // @ts-ignore
            .x(d => subplot._renderer.scale_x(d.date))
            // @ts-ignore
            .y(d => subplot._renderer.scale_y(d.value))
    );
}

function area_draw_fn(subplot) {
    const shift_y = subplot.get_coord_shifts()[1];
    const base = subplot._renderer.scale_y(shift_y);
    return (
        d3
            .area()
            .curve(d3.curveLinear)
            // @ts-ignore
            .x(d => subplot._renderer.scale_x(d.date))
            // @ts-ignore
            .y1(d => subplot._renderer.scale_y(d.value))
            .y0(d => {
                if (subplot.stack_values != null)
                    return subplot._renderer.scale_y(
                        // @ts-ignore
                        subplot.stack_values[d.timestamp] || 0
                    );
                else return base;
            })
    );
}

function graph_data_path(subplot, path_type, status_cls) {
    return subplot.svg
        .selectAll("g.graph_data path." + path_type)
        .data([subplot.transformed_data])
        .join(enter =>
            enter.append("g").classed("graph_data", true).append("path")
        )
        .attr("class", `${path_type}${status_cls ? " " + status_cls : ""}`)
        .classed((subplot.definition.css_classes || []).join(" "), true);
}

function draw_subplot(subplot, path_type, status_cls, styles) {
    const path = graph_data_path(subplot, path_type, status_cls);
    const path_fn = (path_type === "line" ? line_draw_fn : area_draw_fn)(
        subplot
    );

    const plot = subplot._renderer.transition(path).attr("d", d => path_fn(d));
    if (!status_cls)
        Object.entries(styles).forEach(([property, value]) =>
            plot.style(property, value)
        );
}

// Renders a single uninterrupted line
class LinePlot extends SubPlot {
    ident() {
        return "line";
    }

    render() {
        draw_subplot(this, "line", "", {
            fill: "none",
            opacity: this.get_opacity() || 1,
            stroke: this.get_color(),
            "stroke-width": this.definition.stroke_width || 2,
        });
    }

    get_color() {
        const color = SubPlot.prototype.get_color.call(this);
        const classes = (this.definition.css_classes || []).concat("line");
        return color != undefined
            ? color
            : d3.color(this._get_css("stroke", "path", classes));
    }
}

// Renders an uninterrupted area
class AreaPlot extends SubPlot {
    ident() {
        return "area";
    }

    render() {
        const color = this.get_color();
        const svc_status_display = cmk_figures.getIn(
            this,
            "definition",
            "status_display"
        );
        let status_cls = cmk_figures.svc_status_css(
            "background",
            svc_status_display
        );
        // Give svcstate class default, when stautus in plot_def, otherwise plot_def style overtakes
        if (svc_status_display && !status_cls) status_cls = "svcstate";

        draw_subplot(this, "area", status_cls, {
            fill: color,
            opacity: this.get_opacity(),
        });
        if (this.definition.style === "with_topline")
            draw_subplot(this, "line", status_cls, {
                "stroke-width": this.definition.stroke_width || 2,
                fill: "none",
                stroke: color,
            });
    }

    get_color() {
        const color = SubPlot.prototype.get_color.call(this);
        const classes = (this.definition.css_classes || []).concat("area");
        return color != undefined
            ? color
            : d3.color(this._get_css("fill", "path", classes));
    }

    get_opacity() {
        const opacity = this.definition.opacity;
        const classes = (this.definition.css_classes || []).concat("area");
        return opacity != undefined
            ? opacity
            : this._get_css("opacity", "path", classes);
    }
}

// Renders scatterplot points on a canvas
// Provides quadtree to find points on canvas
class ScatterPlot extends SubPlot {
    quadtree;
    canvas;
    _last_canvas_size;
    ident() {
        return "scatterplot";
    }

    constructor(definition) {
        super(definition);
        this.quadtree = null;
        this.canvas = null;
        return this;
    }

    prepare_render() {
        SubPlot.prototype.prepare_render.call(this);
        const plot_size = this._renderer.plot_size;
        const fo = this.main_g
            .selectAll("foreignObject.canvas_object")
            .data([plot_size])
            .join("foreignObject")
            .style("pointer-events", "none")
            .classed("canvas_object", true)
            .attr("width", d => d.width)
            .attr("height", d => d.height);

        const body = fo
            .selectAll("xhtml")
            .data([null])
            .join("xhtml")
            .style("margin", "0px");

        this.canvas = body
            .selectAll("canvas")
            .data([plot_size])
            .join("canvas")
            .classed("subplot", true)
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", d => d.width)
            .attr("height", d => d.height);
    }

    render() {
        const scale_x = this._renderer.scale_x;
        const scale_y = this._renderer.scale_y;
        this.transformed_data.forEach(point => {
            point.scaled_x = scale_x(point.date);
            point.scaled_y = scale_y(point.value);
        });

        this.quadtree = d3
            .quadtree()
            // @ts-ignore
            .x(d => d.scaled_x)
            // @ts-ignore
            .y(d => d.scaled_y)
            .addAll(this.transformed_data);
        this.redraw_canvas();
    }

    redraw_canvas() {
        const plot_size = this._renderer.plot_size;
        const ctx = this.canvas.node().getContext("2d");
        if (!this._last_canvas_size) this._last_canvas_size = plot_size;

        ctx.clearRect(
            -1,
            -1,
            this._last_canvas_size.width + 2,
            this._last_canvas_size.height + 2
        );
        const canvas_data = ctx.getImageData(
            0,
            0,
            plot_size.width,
            plot_size.height
        );

        const color = this.get_color();
        // @ts-ignore
        const r = color.r;
        // @ts-ignore
        const b = color.b;
        // @ts-ignore
        const g = color.g;
        this.transformed_data.forEach(point => {
            if (point.scaled_x > plot_size.width || point.scaled_x < 0) return;
            const index =
                (parseInt(point.scaled_x) +
                    parseInt(point.scaled_y) * plot_size.width) *
                4;
            canvas_data.data[index + 0] = r;
            canvas_data.data[index + 1] = g;
            canvas_data.data[index + 2] = b;
            canvas_data.data[index + 3] = 255;
        });
        ctx.putImageData(canvas_data, 0, 0);
        this._last_canvas_size = plot_size;
    }

    get_color() {
        const color = SubPlot.prototype.get_color.call(this);
        return color != undefined
            ? color
            : d3.color(this._get_css("fill", "circle", ["scatterdot"]));
    }
}

// Renders multiple bars, each based on date->end_date
class BarPlot extends SubPlot {
    _bars;

    ident() {
        return "bar";
    }

    render() {
        const plot_size = this._renderer.plot_size;
        const bars = this.svg.selectAll("rect.bar").data(this.transformed_data);
        bars.exit().remove();

        const classes = this.definition.css_classes || [];
        const bar_spacing = classes.includes("barbar_chart") ? 2 : 4;
        const css_classes = classes.concat("bar").join(" ");

        this._bars = bars
            .enter()
            .append("a")
            .attr("xlink:href", d => d.url)
            .append("rect")
            // Add new bars
            .each((d, idx, nodes) =>
                this._renderer.tooltip_generator.add_support(nodes[idx])
            )
            .classed("bar", true)
            .attr("y", plot_size.height)
            .merge(bars)
            // Update new and existing bars
            .attr("x", d => this._renderer.scale_x(d.date))
            .attr(
                "width",
                d =>
                    this._renderer.scale_x(
                        new Date(d.ending_timestamp * 1000)
                    ) -
                    this._renderer.scale_x(d.date) -
                    bar_spacing
            )
            .attr("class", css_classes);

        this._renderer
            .transition(this._bars)
            .style("opacity", this.get_opacity())
            .attr("fill", this.get_color())
            .attr("rx", 2)
            .attr("y", d => this._renderer.scale_y(d.value) - 1)
            .attr("height", d => {
                let y_base = 0;
                if (this.stack_values != null)
                    y_base = this.stack_values[d.timestamp] || 0;
                return (
                    plot_size.height -
                    this._renderer.scale_y(d.value - y_base) +
                    1
                );
            });
    }

    get_color() {
        const color = SubPlot.prototype.get_color.call(this);
        const classes = (this.definition.css_classes || []).concat("bar");
        return color != undefined
            ? color
            : d3.color(this._get_css("fill", "rect", classes));
    }
}
// Renders a single value
// Per default, the latest timestamp of the given timeline is used
class SingleValuePlot extends SubPlot {
    ident() {
        return "single_value";
    }

    render() {
        const domain = cmk_figures.adjust_domain(
            cmk_figures.calculate_domain(this.transformed_data),
            this.definition.metric.bounds
        );

        const last_value = this.transformed_data.find(
            element => element.last_value
        );
        const value = cmk_figures.renderable_value(
            last_value,
            domain,
            this.definition
        );

        const plot_size = this._renderer.plot_size;
        const svc_status_display = cmk_figures.getIn(
            this,
            "definition",
            "status_display"
        );

        const background_status_cls = cmk_figures.svc_status_css(
            "background",
            svc_status_display
        );
        const label_paint_style = cmk_figures.getIn(
            svc_status_display,
            "paint"
        );
        const label_status_cls = cmk_figures.svc_status_css(
            label_paint_style,
            svc_status_display
        );
        const state_font_size = 14;
        const state_is_visible = label_paint_style && label_status_cls;

        cmk_figures.background_status_component(this.svg, {
            size: {
                width: plot_size.width,
                height: plot_size.height,
            },
            css_class: background_status_cls,
            visible: background_status_cls !== "",
        });

        cmk_figures.state_component(this._renderer, {
            visible: state_is_visible,
            label: svc_status_display.msg,
            css_class: label_status_cls,
            font_size: state_font_size,
        });

        cmk_figures.metric_value_component(this.svg, {
            value: value,
            ...cmk_figures.metric_value_component_options_big_centered_text(
                plot_size,
                {
                    position: {
                        y:
                            (plot_size.height -
                                (state_is_visible
                                    ? 1.5 * state_font_size
                                    : 0)) /
                            2,
                    },
                }
            ),
        });
    }

    get_color() {
        return d3.color("white");
    }
}

class SubPlotFactory {
    private _plot_types: Record<string, typeof SubPlot>;
    constructor() {
        this._plot_types = {};
    }

    get_plot(plot_type) {
        return this._plot_types[plot_type];
    }

    register(subplot: typeof SubPlot) {
        const instance = new subplot(null);
        this._plot_types[instance.ident()] = subplot;
    }
}

const subplot_factory = new SubPlotFactory();
subplot_factory.register(LinePlot);
subplot_factory.register(AreaPlot);
subplot_factory.register(ScatterPlot);
subplot_factory.register(BarPlot);
subplot_factory.register(SingleValuePlot);

class CmkGraphTimeseriesFigure extends TimeseriesFigure {
    _small_legend;
    ident() {
        return "cmk_graph_timeseries";
    }

    constructor(div_selector, fixed_size = null) {
        super(div_selector, fixed_size);
        this.subscribe_data_pre_processor_hook(data =>
            this._convert_graph_to_figures(data)
        );
        this._div_selection.classed("graph", true).style("width", "100%");
    }

    _setup_legend() {
        this._small_legend = false;
        this._legend = this._div_selection
            .select("div.figure_content")
            .append("div")
            .classed("figure_legend graph_with_timeranges graph", true)
            .style("position", "absolute");
    }

    _get_legend_height() {
        if (!this._legend || this._small_legend) return 0;
        return this._legend.node().getBoundingClientRect().height + 20;
    }

    _convert_graph_to_figures(graph_data) {
        const plot_definitions: PlotDefinition[] = [];
        const data: {timestamp: number; value: number; tag: string}[] = [];

        // Metrics
        const step = graph_data.graph.step;
        const start_time = graph_data.graph.start_time;
        graph_data.graph.curves.forEach((curve, idx) => {
            const curve_tag = "metric_" + idx;
            const stack_tag = "stack_" + curve_tag;
            const use_stack =
                // @ts-ignore
                curve.type == "area" && d3.max(curve.points, d => d[0]) > 0;
            curve.points.forEach((point, idx) => {
                const timestamp = start_time + idx * step;
                let value = 0;
                let base_value = 0;
                if (curve.type == "line") value = point;
                else {
                    base_value = point[0];
                    value = point[1];
                }

                data.push({
                    timestamp: timestamp,
                    value: value - (base_value || 0),
                    tag: curve_tag,
                });

                if (use_stack)
                    data.push({
                        timestamp: timestamp,
                        value: base_value,
                        tag: stack_tag,
                    });
            });

            const plot_definition = {
                hidden: false,
                label: curve.title,
                plot_type: curve.type,
                color: curve.color,
                id: curve_tag,
                is_scalar: false,
                use_tags: [curve_tag],
            };

            if (use_stack) {
                plot_definitions.push({
                    hidden: true,
                    label: "stack_base " + curve.title,
                    plot_type: "line",
                    color: curve.color,
                    id: stack_tag,
                    is_scalar: false,
                    use_tags: [stack_tag],
                });
                plot_definition["stack_on"] = stack_tag;
            }
            plot_definitions.push(plot_definition);
        });

        // Levels
        const start = d3.min(data, d => d.timestamp);
        const end = d3.max(data, d => d.timestamp);
        graph_data.graph.horizontal_rules.forEach((rule, idx) => {
            const rule_tag = "level_" + idx;
            plot_definitions.push({
                hidden: false,
                label: rule[3],
                plot_type: "line",
                color: rule[2],
                id: rule_tag,
                is_scalar: true,
                use_tags: [rule_tag],
            });
            data.push({
                // @ts-ignore
                timestamp: start,
                value: rule[0],
                tag: rule_tag,
            });
            data.push({
                // @ts-ignore
                timestamp: end,
                value: rule[0],
                tag: rule_tag,
            });
        });

        return {
            plot_definitions: plot_definitions,
            data: data,
        };
    }

    _process_api_response(graph_data) {
        this.process_data(graph_data);
        this._fetch_data_latency =
            +(new Date().getTime() - this._fetch_start) / 1000;
    }

    render_legend() {
        const domains = this.scale_x.domain();
        const start = Math.trunc(domains[0].getTime() / 1000);
        const end = Math.trunc(domains[1].getTime() / 1000);
        const subplot_data: {definition: PlotDefinition; data}[] = [];
        this._subplots.forEach(subplot => {
            subplot_data.push({
                definition: subplot.definition,
                data: subplot.get_legend_data(start, end),
            });
        });

        this._div_selection
            .selectAll("div.toggle")
            .data([null])
            .enter()
            .append("div")
            .classed("toggle noselect", true)
            .style("position", "absolute")
            .style("bottom", "0px")
            .text("Toggle legend")
            .on("click", () => {
                this._small_legend = !this._small_legend;
                this.render_legend();
                this.resize();
                this.render();
            });

        this._render_legend(subplot_data, this._small_legend);
    }

    _render_legend(subplot_data, small) {
        const new_table = this._legend.selectAll("tbody").empty();
        const table = this._legend
            .selectAll("tbody")
            .data([null])
            .join(enter =>
                enter
                    .append("table")
                    .classed("legend", true)
                    .style("width", "100%")
                    .append("tbody")
            );

        table
            .selectAll("tr.headers")
            .data([["", "MINIMUM", "MAXIMUM", "AVERAGE", "LAST"]])
            .join("tr")
            .classed("headers", true)
            .selectAll("th")
            .data(d => d)
            .join("th")
            .text(d => d);

        // Metrics
        let rows = table
            .selectAll("tr.metric")
            .data(
                subplot_data.filter(d => d.definition.id.startsWith("metric_"))
            )
            .join("tr")
            .classed("metric", true);
        rows.selectAll("td.name")
            .data(d => [d])
            .enter()
            .append("td")
            .classed("name small", true)
            .each((d, idx, nodes) => {
                const td = d3.select(nodes[idx]);
                td.classed("name", true);
                td.append("div")
                    .classed("color", true)
                    .style("background", d.definition.color);
                td.append("label").text(d.definition.label);
            });
        rows.selectAll("td.min")
            .data(d => [d])
            .join("td")
            .classed("scalar min", true)
            .text(d =>
                d.data.data.length == 0 ? "NaN" : d.data.y[0].toFixed(2)
            );
        rows.selectAll("td.max")
            .data(d => [d])
            .join("td")
            .classed("scalar max", true)
            .text(d =>
                d.data.data.length == 0 ? "NaN" : d.data.y[1].toFixed(2)
            );
        rows.selectAll("td.average")
            .data(d => [d])
            .join("td")
            .classed("scalar average", true)
            .text(d =>
                d.data.data.length == 0
                    ? "NaN"
                    : // @ts-ignore
                      d3.mean(d.data.data, d => d.value).toFixed(2)
            );
        rows.selectAll("td.last")
            .data(d => [d])
            .join("td")
            .classed("scalar last", true)
            .text(d => {
                if (d.data.data.length == 0) return "NaN";

                if (d.data.data[0].value == null) return "NaN";

                if (d.data.data[0].unstacked_value)
                    return d.data.data[0].unstacked_value.toFixed(2);
                else return d.data.data[0].value.toFixed(2);
            });

        // Levels
        rows = table
            .selectAll("tr.level")
            .data(
                subplot_data.filter(d => d.definition.id.startsWith("level_"))
            )
            .join(enter =>
                enter
                    .append("tr")
                    .classed("level scalar", true)
                    .each((d, idx, nodes) => {
                        if (idx == 0)
                            d3.select(nodes[idx]).classed("first", true);
                    })
            );
        rows.selectAll("td.name")
            .data(d => [d])
            .enter()
            .append("td")
            .classed("name", true)
            .each((d, idx, nodes) => {
                const td = d3.select(nodes[idx]);
                td.classed("name", true);
                td.append("div")
                    .classed("color", true)
                    .style("background", d.definition.color);
                td.append("label").text(d.definition.label);
            });
        rows.selectAll("td.min")
            .data(d => [d])
            .join("td")
            .classed("scalar min", true)
            .text("");
        rows.selectAll("td.max")
            .data(d => [d])
            .join("td")
            .classed("scalar max", true)
            .text("");
        rows.selectAll("td.average")
            .data(d => [d])
            .join("td")
            .classed("scalar average", true)
            .text("");
        rows.selectAll("td.last")
            .data(d => [d])
            .join("td")
            .classed("scalar last", true)
            .text(d =>
                d.data.data.length == 0
                    ? "NaN"
                    : d.data.data[0].value.toFixed(2)
            );

        if (small) {
            this._legend.selectAll("th").style("display", "none");
            this._legend.selectAll("td").style("display", "none");
            this._legend.selectAll("td.small").style("display", null);
            this.transition(this._legend)
                .style("top", this.margin.top + "px")
                .style("width", null)
                .style("right", "20px")
                .style("left", null);
        } else {
            this._legend.selectAll("th").style("display", null);
            this._legend.selectAll("td").style("display", null);
            if (new_table)
                this._legend
                    .style("width", "100%")
                    .style(
                        "top",
                        this.figure_size.height -
                            this._get_legend_height() +
                            "px"
                    )
                    .style("left", "40px");
            else
                this.transition(this._legend)
                    .style("width", "100%")
                    .style(
                        "top",
                        this.figure_size.height -
                            this._get_legend_height() +
                            "px"
                    )
                    .style("left", "40px");
        }
    }
}
cmk_figures.figure_registry.register(CmkGraphTimeseriesFigure);

class CmkGraphShifter extends CmkGraphTimeseriesFigure {
    _cutter_div;
    _shifts;
    ident() {
        return "cmk_graph_shifter";
    }

    constructor(div_selector, fixed_size = null) {
        super(div_selector, fixed_size);
        this.subscribe_data_pre_processor_hook(data => {
            this._apply_shift_config(data);
            return data;
        });
        this._cutter_div = null;
        this._shifts = [];
    }

    _apply_shift_config(data) {
        const new_definitions = data.plot_definitions.filter(
            d => d.is_shift !== true
        );
        this._shifts.forEach(config => {
            if (config.seconds === 0) return;
            const shift = JSON.parse(
                JSON.stringify(
                    this._subplots_by_id[config.shifted_id].definition
                )
            );
            const seconds = config.seconds;
            shift.id += "_shifted";
            shift.color = config.color;
            shift.shift_seconds = seconds;
            shift.label += config.label_suffix;
            shift.opacity = 0.5;
            shift.is_shift = true;
            new_definitions.push(shift);
        });
        data.plot_definitions = new_definitions;
    }

    initialize() {
        CmkGraphTimeseriesFigure.prototype.initialize.call(this);
        this._setup_cutter_options_panel();
    }

    update_gui() {
        CmkGraphTimeseriesFigure.prototype.update_gui.call(this);
        this._update_cutter_options_panel();
    }

    _setup_cutter_options_panel() {
        this._cutter_div = this._div_selection
            .select("div.figure_content")
            .selectAll("div.cutter_options")
            .data([null])
            .join(enter =>
                enter
                    .append("div")
                    .style("position", "absolute")
                    .style("top", "0px")
                    .style("left", 40 + this.figure_size.width + "px")
                    .classed("cutter_options noselect", true)
            );

        this._cutter_div
            .append("label")
            .style("margin-left", "-60px")
            .style("border", "grey")
            .style("border-style", "solid")
            .text("Shift data")
            .on("click", (event, d, idx, nodes) => {
                const node = d3.select(nodes[idx]);
                const active = !node.classed("active");
                node.classed("active", active);
                this._cutter_div
                    .select("div.options")
                    .transition()
                    .style("width", active ? null : "0px");
                // @ts-ignore
                node.style("background", active ? "green" : null);
            });
        const options = [
            {id: "Hours", min: 0, max: 24},
            {id: "Days", min: 0, max: 31},
        ];
        const div_options = this._cutter_div
            .append("div")
            .style("overflow", "hidden")
            .style("width", "0px")
            .classed("options", true);
        const new_table = div_options
            .selectAll("table")
            .data([null])
            .enter()
            .append("table");

        const new_rows = new_table
            .selectAll("tr.shift_option")
            .data(options)
            .enter()
            .append("tr")
            .classed("shift_option", true);
        new_rows.append("td").text(d => d.id);
        new_rows
            .append("td")
            .text("0")
            .classed("value", true)
            .attr("id", d => d.id);
        new_rows
            .append("td")
            .append("input")
            .attr("type", "range")
            .attr("min", d => d.min)
            .attr("max", d => d.max)
            .attr("value", 0)
            .on("change", (event, d) => {
                this._cutter_div
                    .selectAll("td.value#" + d.id)
                    .text(event.target.value);
                this._update_shifts();
            });
    }

    _update_cutter_options_panel() {
        const table = this._cutter_div
            .select("div.options")
            .selectAll("table.metrics")
            .data([null])
            .join("table")
            .classed("metrics", true);
        const rows = table
            .selectAll("tr.metric")
            .data(
                this._subplots.filter(
                    d =>
                        !(
                            d.definition.is_scalar ||
                            d.definition.is_shift ||
                            d.definition.hidden
                        )
                ),
                d => d.definition.id
            )
            .join("tr")
            .classed("metric", true);
        rows.selectAll("input")
            .data(d => [d])
            .join("input")
            .attr("type", "checkbox")
            .style("display", "inline-block")
            .on("change", () => this._update_shifts());
        const metric_td = rows
            .selectAll("td.color")
            .data(d => [d])
            .enter()
            .append("td")
            .classed("color", true);
        metric_td
            .append("div")
            .classed("color", true)
            .style("background", d => d.definition.color);
        metric_td.append("label").text(d => d.definition.label);
    }

    _update_shifts() {
        const hours = parseInt(
            this._cutter_div.selectAll("td.value#Hours").text()
        );
        const days = parseInt(
            this._cutter_div.selectAll("td.value#Days").text()
        );

        const checked_metrics = this._cutter_div.selectAll(
            "input[type=checkbox]:checked"
        );
        this._shifts = [];
        checked_metrics.each(d => {
            this._shifts.push({
                shifted_id: d.definition.id,
                seconds: hours * 3600 + days * 86400,
                color: "white",
                label_suffix:
                    "- shifted " + days + " days, " + hours + " hours",
            });
        });

        this._apply_shift_config(this._data);
        this._legend.selectAll("table").remove();
        this.update_gui();
    }
}

cmk_figures.figure_registry.register(CmkGraphShifter);
